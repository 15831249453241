import { mapInputTypeToHtmlInputType } from '@app/bffRenderer/utils/input';
import GenericButton from '@app/components/GenericButton';
import { addPaymentInstrumentChallengeResult } from '@app/contexts/mainContext/actions/addPaymentInstrumentChallengeResult';
import { invalidateSessionCache } from '@app/contexts/mainContext/actions/invalidateSessionCache';
import { resetPaymentInstrumentsChallenges } from '@app/contexts/mainContext/actions/resetPaymentInstrumentsChallenges';
import { useCheckoutType } from '@app/contexts/mainContext/selectors/useCheckoutType';
import { TRACKING_EVENTS } from '@app/contexts/mainContext/tracker';
import logger from '@app/logger';
import { useSecurityCodeValidation } from '@app/modules/paymentMethod/queries';
import confirmCheckout from '@app/services/checkout/confirmCheckout';
import { addQueryParamsToUrlString } from '@app/utils/queryString';
import { ArrowLeft, Card, SimpleButton } from '@appscore/web-components';
import CardErrorIcon from '@components/CardErrorIcon';
import { BrandError } from '@components/Error';
import ErrorDialog from '@components/ErrorDialog';
import FixedHeader from '@components/FixedHeader';
import OverlaySpinner from '@components/OverlaySpinner';
import ShellBody from '@components/ShellBody';
import ShellFooter from '@components/ShellFooter';
import Spinner from '@components/Spinner';
import ValidatedInputText from '@components/ValidatedInputText';
import { useNavigateBack } from '@hooks/navigation/useNavigateBack';
import { useComponentTracking } from '@hooks/tracking/useComponentTracking';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import SecurityCodeHelp from './components/SecurityCodeHelp';

import { useAppDispatch } from '@app/contexts/mainContext/selectors/useAppDispatch';
import { useExternalRouterNavigation } from '@checkout-ui/backend-driven';
import { CHALLENGES_CODES } from '@commons/constants/challengesCodes';
import { useLocation, useNavigate } from 'react-router-dom';
import CardTokenizer from '../../cardTokenization';
import { BFF_COMPONENTS_TYPES } from '../../constants/bff';
import PaymentInstrumentToValidate from './components/PaymentInstrumentToValidate';
import messages from './messages';

function SecurityCodeValidation() {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const paymentInstrumentId = location?.state?.paymentInstrumentId;
  const { isLoading, error, data, refetch, isFetching } = useSecurityCodeValidation(paymentInstrumentId);
  const navigate = useNavigate();
  const { navigateBack } = useNavigateBack();
  const savedCheckoutType = useCheckoutType();
  const { externalRouterNavigation } = useExternalRouterNavigation();
  const dispatch = useAppDispatch();

  const [cvv, setCvv] = useState('');
  const [valid, setValid] = useState(false);
  const trackingData = isFetching ? undefined : data?.tracking;
  const checkoutType = trackingData?.checkoutType || savedCheckoutType;
  const actionEvents = [
    TRACKING_EVENTS.CODE_VALIDATION_CLOSED(checkoutType),
    TRACKING_EVENTS.CODE_VALIDATION_ADDED(checkoutType),
    TRACKING_EVENTS.TRANSACTION_SUCCESS(checkoutType),
    TRACKING_EVENTS.TRANSACTION_FAILED(checkoutType),
  ];
  const onMountEvent = TRACKING_EVENTS.CODE_VALIDATION_LOADED(checkoutType);
  const [trackCloseClick, trackConfirm, trackTransactionSuccess, trackTransactionFailed] = useComponentTracking({
    commonTrackingData: trackingData,
    onMountEvent,
    actionEvents,
  });
  const [tokenizationError, setTokenizationError] = useState(false);
  const [confirmingCheckout, setConfirmingCheckout] = useState(false);
  const [confirmError, setConfirmError] = useState(false);
  const [messageError, setMessageError] = useState({});

  if (isLoading) return <Spinner />;

  const cardVault = data?.payment?.vault?.provider;
  const providerCardId = data?.payment?.vault?.tokenization_params?.card_token;

  if (error)
    return (
      <BrandError
        primaryActionLabel="Reintentar"
        primaryActionClick={refetch}
        secondaryActionLabel="Volver"
        secondaryActionClick={() => navigateBack()}
      />
    );

  if (!cardVault || !providerCardId) {
    logger.error('[SecurityCodeValidation]', 'missing tokenization params', cardVault, providerCardId);
    return (
      <BrandError
        primaryActionLabel="Reintentar"
        primaryActionClick={refetch}
        secondaryActionLabel="Volver"
        secondaryActionClick={() => navigateBack()}
      />
    );
  }

  const header = data?.render?.title || 'Código de seguridad';
  const confirmSecurityCodeLabel = data?.render?.call_to_action || 'Continuar';
  const paymentInstrumentComponent = data?.render?.components.find(
    (c) => c.type === BFF_COMPONENTS_TYPES.PAYMENT_INSTRUMENT,
  );

  const textInputComponent = data?.render?.components.find((c) => c.type === BFF_COMPONENTS_TYPES.INPUT_TEXT);
  const suffix =
    textInputComponent?.suffix?.type === BFF_COMPONENTS_TYPES.HELP_CVV_DIALOG ? <SecurityCodeHelp /> : null;

  const onClose = () => {
    trackCloseClick();
    navigateBack();
  };

  const onInvalidCvv = () => {
    if (valid) {
      setValid(false);
    }
  };

  const onValidCvv = () => {
    if (!valid) {
      setValid(true);
    }
  };

  const defaultConfirmMessageError = {
    title: 'No pudimos procesar tu pago',
    description: 'Intentá más tarde o volvé a probar con otro medio de pago',
    onFirstOptionAction: () => setConfirmError(false),
  };

  // TODO: duplicated code in CheckoutSummary. Refactor it
  const confirmCheckoutAction = async () => {
    try {
      const response = await confirmCheckout();
      if (response.result === 'ERROR' && response.action?.render?.type === 'DIALOG') {
        trackTransactionFailed();
        const confirmCheckoutComponents = response.action?.render?.components || [];
        const errorDialog = confirmCheckoutComponents.find((c) => c.type === BFF_COMPONENTS_TYPES.ERROR_DIALOG);

        setMessageError({
          title: errorDialog?.title,
          description: errorDialog?.description,
          icon: <CardErrorIcon icon={confirmError.data?.icon} />,
          firstOptionLabel: errorDialog?.call_to_action,
          onFirstOptionAction: () => navigateBack(),
        });
        setConfirmError(true);
        setConfirmingCheckout(false);
        resetPaymentInstrumentsChallenges(dispatch);
        return;
      }

      if (response.result === 'SUCCESS') {
        queryClient.invalidateQueries({ refetchActive: false });
        trackTransactionSuccess();
        invalidateSessionCache(dispatch);
        if (response?.action?.internal) {
          const url = addQueryParamsToUrlString(response?.action?.redirect_url, response?.action?.query_params);
          navigate(url);
        } else {
          externalRouterNavigation(response.action?.redirect_url, { queryParams: response.action?.query_params });
        }
        return;
      }

      logger.error('[SecurityCodeValidation]', 'error confirming checkout, unknown response result:', response.result);
      trackTransactionFailed();
      setConfirmError(true);
      setMessageError(defaultConfirmMessageError);
      setConfirmingCheckout(false);
      resetPaymentInstrumentsChallenges(dispatch);
    } catch (err) {
      logger.error('[SecurityCodeValidation]', 'error confirming checkout:', err);
      trackTransactionFailed();
      setConfirmError(true);
      setMessageError(defaultConfirmMessageError);
      setConfirmingCheckout(false);
      resetPaymentInstrumentsChallenges(dispatch);
    }
  };

  const onConfirmClick = async () => {
    try {
      setConfirmingCheckout(true);
      const tokenizer = new CardTokenizer(cardVault);
      // TODO: Maybe the tokenizer module has to do the mapping between tokenization_params
      // coming from Checkout-BFF to each provider params contract
      const tokenizationResult = await tokenizer.createTokenForCard(false, {
        spreedlyCardId: providerCardId,
        cardSecurityCode: cvv,
      });
      addPaymentInstrumentChallengeResult(
        dispatch,
        paymentInstrumentId,
        CHALLENGES_CODES.CARD_SECURITY_CODE,
        tokenizationResult,
      );
      trackConfirm({ cvvLength: cvv.length, cvvValid: true });
      await confirmCheckoutAction();
    } catch (err) {
      trackConfirm({ cvvLength: cvv.length, cvvValid: false });
      logger.error('[SecurityCodeValidation]', 'createTokenForCard error:', err);
      setMessageError({
        title: formatMessage(messages.tokenizationErrorTitle),
        description: formatMessage(messages.tokenizationErrorDescription),
        icon: <CardErrorIcon />,
        firstOptionLabel: formatMessage(messages.tokenizationErrorActionLabel),
        onFirstOptionAction: () => setTokenizationError(false),
      });
      setTokenizationError(true);
      setConfirmingCheckout(false);
      resetPaymentInstrumentsChallenges(dispatch);
    }
  };

  return (
    <>
      {confirmingCheckout ? <OverlaySpinner /> : null}
      <FixedHeader
        action={
          <GenericButton title="close" onClick={onClose}>
            <ArrowLeft size="medium" />
          </GenericButton>
        }
      >
        {header}
      </FixedHeader>
      <ShellBody>
        <PaymentInstrumentToValidate
          icon={paymentInstrumentComponent?.icon}
          title={paymentInstrumentComponent?.title}
          subtitle={paymentInstrumentComponent?.subtitle}
        />
        <Card>
          <ValidatedInputText
            label={textInputComponent?.label}
            errorText={textInputComponent?.error_text}
            regexString={textInputComponent?.regex}
            helpText={textInputComponent?.help_text}
            max={textInputComponent?.max}
            type={mapInputTypeToHtmlInputType(textInputComponent?.input_type)}
            onChange={setCvv}
            onValid={onValidCvv}
            onInvalid={onInvalidCvv}
            value={cvv}
            suffix={suffix}
            autoFocus
          />
        </Card>
      </ShellBody>
      <ShellFooter>
        <SimpleButton
          disabled={confirmingCheckout || !valid}
          color="primary"
          label={confirmSecurityCodeLabel}
          size="full"
          type="button"
          onClick={onConfirmClick}
        />
      </ShellFooter>
      <ErrorDialog
        show={tokenizationError || confirmError}
        title={messageError?.title}
        description={messageError?.description}
        icon={messageError?.icon}
        firstOptionLabel={messageError?.firstOptionLabel || 'Cerrar'}
        onFirstOptionAction={messageError?.onFirstOptionAction || defaultConfirmMessageError.onFirstOptionAction}
      />
    </>
  );
}

export default SecurityCodeValidation;
