import { createGlobalStyle } from 'styled-components';

const CheckoutGlobalStyleSheet = createGlobalStyle`
  body, html {
    height: 100%;
  }
  * {
    font-family: 'TT Commons Pro', Segma !important;
  }
`;

export default CheckoutGlobalStyleSheet;
