import App, { LoadAppError } from '@app/app';
import { setBasenamePrefix } from '@commons/services/routes.service';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import React from 'react';
import CheckoutSummaryLoading from './components/CheckoutSummaryLoading';
import { useIsDesktop } from './hooks/layout/useIsDesktop';
import { useCheckoutParams } from './hooks/setup/useCheckoutParams';
import { useCheckoutSession } from './hooks/setup/useCheckoutSession';
import { useDismissParam } from './hooks/setup/useDismissParam';
import { useEntryPointParam } from './hooks/setup/useEntryPointParam';
import { useMicrositesPrefetch } from './hooks/setup/useMicrositesPrefetch';
import { usePublicEnvironmentSetup } from './hooks/setup/usePublicEnvironmentSetup';
import { useTrackerLoader } from './hooks/setup/useTrackerLoader';
import { prefixAxiosRequestPath } from './services/axios';
import { theme } from './theme';
import { RootGlobalStyleSheet } from './theme/ConditionalGlobalStyleSheet';
import { ThemeProvider } from 'styled-components';

const PAYMENT_METHODS_MICROSITE_ID = 'checkout-payment-methods';

const Root = (customProps) => {
  setBasenamePrefix(customProps?.appShellBasePath);
  prefixAxiosRequestPath(customProps?.appShellBasePath);
  const { basketId, checkoutType } = useCheckoutParams();

  const tracker = useTrackerLoader(customProps?.trackerPromise);
  const { isLoading: isLoadingEnv, data: envData, error: errorEnv } = usePublicEnvironmentSetup();
  const {
    isLoading: isLoadingCheckout,
    checkoutSession,
    error: errorCheckout,
  } = useCheckoutSession({
    basketId,
    checkoutType,
  });
  useMicrositesPrefetch([PAYMENT_METHODS_MICROSITE_ID]);
  const dismiss = useDismissParam();
  const entryPoint = useEntryPointParam();
  const isDesktop = useIsDesktop();

  if (isLoadingEnv || isLoadingCheckout || !tracker) {
    return (
      <ThemeProvider theme={theme}>
        <FenixProvider config={{ revamp: customProps?.enableRevamp }}>
          <>
            <RootGlobalStyleSheet />
            <CheckoutSummaryLoading checkoutType={checkoutType} entryPoint={entryPoint} isDesktop={isDesktop} />
          </>
        </FenixProvider>
      </ThemeProvider>
    );
  }

  if (errorEnv || errorCheckout) {
    return (
      <ThemeProvider theme={theme}>
        <FenixProvider config={{ revamp: customProps?.enableRevamp }}>
          <>
            <RootGlobalStyleSheet />
            <LoadAppError />
          </>
        </FenixProvider>
      </ThemeProvider>
    );
  }

  return (
    <App
      tracker={tracker}
      context={envData}
      initialState={{ ...checkoutSession, dismiss, entryPoint }}
      {...customProps}
    />
  );
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {};

export default Root;
