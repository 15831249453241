import { useDeviceInfo } from '@app/contexts/mainContext/selectors/useDeviceInfo';
import { useHardNavigate } from '@hooks/navigation/useHardNavigate';
import { STATUS_CODE } from '@app/modules/shared/constants';
import { getHomeLink } from '@app/utils/deeplinks';
import { BrandError } from '@components/Error';
import PropTypes from 'prop-types';
import React from 'react';

function ErrorPage() {
  const deviceInfo = useDeviceInfo();
  const hardNavigate = useHardNavigate();

  return (
    <BrandError
      primaryActionLabel="Reintentar"
      primaryActionClick={window.location.reload}
      secondaryActionLabel="Ir al inicio"
      secondaryActionClick={() => {
        hardNavigate(getHomeLink(deviceInfo));
      }}
    />
  );
}

ErrorPage.propTypes = {
  errorCode: PropTypes.number,
  errorMessage: PropTypes.string,
};

ErrorPage.defaultProps = {
  errorCode: STATUS_CODE.INTERNAL_ERROR,
};

export default ErrorPage;
