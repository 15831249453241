import sentryHubInstance from '@app/logger/sentry/initialize';
import { theme } from '@app/theme';
import { BrandError } from '@appscore/web-components/BrandError';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';

export const ErrorBoundary = ({ error, enableRevamp, ...props }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  sentryHubInstance?.captureException(error);

  return (
    <ThemeProvider theme={theme}>
      <FenixProvider config={{ revamp: enableRevamp }}>
        <BrandError {...props} />
      </FenixProvider>
    </ThemeProvider>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
