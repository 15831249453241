import { useInvalidateQueriesAndRefetchInactive } from '@app/contexts/reactQuery/hooks/useInvalidateQueriesAndRefetchInactive';
import logger from '@app/logger';
import { QUERIES_NAMES } from '@app/modules/checkout/queries';
import { SimpleButton } from '@appscore/web-components';
import { BackendDrivenPage } from '@checkout-ui/backend-driven';
import { QUERY_PARAM } from '@commons/constants';
import { BrandError } from '@components/Error';
import Spinner from '@components/Spinner';
import { useIsDesktop } from '@hooks/layout/useIsDesktop';
import { useNavigateBack } from '@hooks/navigation/useNavigateBack';
import { getQueryParams } from '@utils/domRouter';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BFF_ACTION_TYPES, BFF_COMPONENTS_TYPES } from '../../constants/bff';
import { useConfirmBilling } from '../../mutations';
import { useBillingScreen } from '../../queries';
import BillingInput from './components/BillingInput';
import { useBillingActionDefinitions } from './hooks/useBillingActionDefinitions';
import messages from './messages';

function Billing() {
  const companyNameQueryParam = getQueryParams(window?.location, QUERY_PARAM.BILLING.COMPANY_NAME);
  const companyNumberQueryParam = getQueryParams(window?.location, QUERY_PARAM.BILLING.COMPANY_NUMBER);
  const [billingScreenData, setBillingScreenData] = useState();
  const [confirmErrors, setConfirmErrors] = useState();
  const [companyName, setCompanyName] = useState();
  const [companyNumber, setCompanyNumber] = useState();
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const { navigateBack } = useNavigateBack();
  const { actionDefinitions } = useBillingActionDefinitions();
  const { formatMessage } = useIntl();
  const { invalidateQueriesAndRefetchInactive } = useInvalidateQueriesAndRefetchInactive();
  const isDesktop = useIsDesktop();

  const { isLoading, isFetching, error, refetch, data } = useBillingScreen();
  // TODO: add isError state to mutation and then check this in BrandError condition
  const { mutate: confirmBilling } = useConfirmBilling();

  useEffect(() => {
    if (data) {
      const newBillingScreenData = {
        ...data,
        children: data?.children?.map((c) => ({ ...c, type: c.id })),
      };
      setBillingScreenData(newBillingScreenData);
    }
  }, [data]);

  if (isLoading || isFetching || (data && !billingScreenData) || isLoadingConfirm) return <Spinner />;
  if (error)
    return (
      <BrandError
        primaryActionLabel="Reintentar"
        primaryActionClick={refetch}
        secondaryActionLabel="Volver"
        secondaryActionClick={() => navigateBack()}
      />
    );

  const headerTitle = billingScreenData?.content?.title || formatMessage(messages.headerTitle);
  const footerText =
    billingScreenData?.children?.find((c) => c.id === BFF_COMPONENTS_TYPES.BILLING_BUTTON)?.content?.title ||
    formatMessage(messages.footerText);
  const components = billingScreenData?.children?.filter((c) => c.id !== BFF_COMPONENTS_TYPES.BILLING_BUTTON) || [];

  const header = {
    actions: [
      {
        type: BFF_ACTION_TYPES.NAVIGATE_BACK,
      },
    ],
    title: headerTitle,
    isOnlyAction: false,
    icon: 'LEFT_ARROW',
  };

  const footer = {
    components: [
      {
        type: BFF_COMPONENTS_TYPES.BILLING_BUTTON,
        data: {
          style: 'PRIMARY',
          text: footerText,
        },
      },
    ],
    footerProps: {
      isDesktop,
    },
  };

  const handleConfirmBilling = async () => {
    setIsLoadingConfirm(true);
    confirmBilling(
      { companyName, companyNumber },
      {
        onSuccess: (data) => {
          const billingValidate = data?.billingValidate;
          setConfirmErrors(billingValidate);
          if (billingValidate && billingValidate?.length === 0) {
            logger.debug('[Billing][CONFIRM_BILLING_SUCCESS]');
            invalidateQueriesAndRefetchInactive([QUERIES_NAMES.CHECKOUT]);
            navigateBack();
            return;
          }
          logger.debug('[Billing][CONFIRM_BILLING_WITH_ERRORS]');
        },
        onError: (error) => logger.error('[Billing][CONFIRM_BILLING_ERROR]', `error: ${error.message}`),
        onSettled: () => setIsLoadingConfirm(false),
      },
    );
  };

  const componentResolvers = {
    [BFF_COMPONENTS_TYPES.BILLING_NAME]: ({ content }) => ({
      component: BillingInput,
      props: {
        type: 'text',
        placeholder: content?.placeholder || formatMessage(messages.companyNameLabel),
        name: content?.name,
        initValue: companyNameQueryParam,
        value: companyName,
        onChange: (newCompanyName) => setCompanyName(newCompanyName),
        errorFromConfirm: confirmErrors?.find((c) => c.field === content?.name),
      },
    }),
    [BFF_COMPONENTS_TYPES.BILLING_NUMBER]: ({ content }) => ({
      component: BillingInput,
      props: {
        type: 'text',
        placeholder: content?.placeholder || formatMessage(messages.companyNumberLabel),
        name: content?.name,
        initValue: companyNumberQueryParam,
        value: companyNumber,
        onChange: (newCompanyNumber) => setCompanyNumber(newCompanyNumber),
        errorFromConfirm: confirmErrors?.find((c) => c.field === content?.name),
      },
    }),
    [BFF_COMPONENTS_TYPES.BILLING_BUTTON]: ({ data: { style, text } }) => ({
      component: SimpleButton,
      props: {
        color: style.toLowerCase(),
        label: text,
        size: 'full',
        type: 'button',
        onClick: handleConfirmBilling,
      },
    }),
  };

  return (
    <BackendDrivenPage
      actionDefinitions={actionDefinitions}
      componentResolvers={componentResolvers}
      components={components}
      header={header}
      footer={footer}
    />
  );
}

export default Billing;
