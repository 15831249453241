import { useInvalidateQueriesAndRefetchInactive } from '@app/contexts/reactQuery/hooks/useInvalidateQueriesAndRefetchInactive';
import logger from '@app/logger';
import { QUERIES_NAMES } from '@app/modules/checkout/queries';
import { useConfirmOrderTime } from '@app/modules/vendors/mutations';
import { usePreOrderTimes } from '@app/modules/vendors/queries';
import { BrandError } from '@components/Error';
import Spinner from '@components/Spinner';
import { useNavigateBack } from '@hooks/navigation/useNavigateBack';
import React, { useEffect, useState } from 'react';
import { DeliveryDetailFooter, DeliveryDetailHeader, ItemList, StyledShellBody } from './components';

const PreOrderTimes = () => {
  const { isLoading, isFetching, error, refetch, data } = usePreOrderTimes();
  const { mutate: confirmOrderTime, isError } = useConfirmOrderTime();
  const { invalidateQueriesAndRefetchInactive } = useInvalidateQueriesAndRefetchInactive();

  const { navigateBack } = useNavigateBack();
  const [selectedTime, setSelectedTime] = useState({});

  useEffect(() => {
    if (data?.orderTimes) {
      setSelectedTime({
        fromZTime: data?.orderTimes.selectedDate,
        asSoonAsPossible: !data?.orderTimes.selectedDate,
      });
    }
  }, [data]);

  if (isLoading || isFetching) return <Spinner />;
  if (error || isError)
    return (
      <BrandError
        primaryActionLabel="Reintentar"
        primaryActionClick={refetch}
        secondaryActionLabel="Volver"
        secondaryActionClick={() => navigateBack()}
      />
    );

  const handleSelectedTime = (time) => setSelectedTime(time);

  const handleConfirmOrderTime = async () => {
    const { fromZTime, asSoonAsPossible = false } = selectedTime;
    const deliveryOrderTime = {
      date: asSoonAsPossible ? null : fromZTime,
      asap: asSoonAsPossible,
    };

    confirmOrderTime(deliveryOrderTime, {
      onSuccess: (data) => {
        if (data) {
          logger.debug('[PreOrderTimes][CONFIRM_ORDER_TIME]');
          invalidateQueriesAndRefetchInactive([QUERIES_NAMES.CHECKOUT]);
          navigateBack();
          return;
        }
        logger.debug('[PreOrderTimes][CONFIRM_DELIVERY_TIME_WITH_ERRORS]');
      },
      onError: (error) => {
        logger.error('[PreOrderTimes][CONFIRM_DELIVERY_ERROR]', `error: ${error.message}`);
      },
    });
  };

  const { deliveryTime, preordertimes: preOrderTimes, rules } = data.orderTimes;

  return (
    <>
      <DeliveryDetailHeader />
      <StyledShellBody>
        <ItemList
          times={preOrderTimes}
          selectedTime={selectedTime}
          deliveryTime={deliveryTime}
          showAsSoonAsPossible={rules.asap}
          onSelectTime={handleSelectedTime}
        />
      </StyledShellBody>
      <DeliveryDetailFooter onSubmit={handleConfirmOrderTime} disabled={!selectedTime} />
    </>
  );
};

export default PreOrderTimes;
